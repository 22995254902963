.toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1000;
}

.toast {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
//   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08), 0px 4px 20px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 10px 15px rgba(0, 0, 0, 0.05);
  min-width: 280px;
  max-width: 400px;
  font-size: 14px;
  color: #374151;
  background: white;
  position: relative;

  opacity: 0;
  transform: translateX(100px); // Start off-screen
  transition: transform 0.3s ease, opacity 0.3s ease;

  &.success {
    border: 1px solid #3dcc72;
    .toast-icon {
      color: #059669;
    }
  }

  &.error {
    border: 1px solid #ef4444;
    .toast-icon {
      color: #dc2626;
    }
  }
}

// When "animate-in" class is added
.toast.animate-in {
  opacity: 1;
  transform: translateX(0);
}

// Ensure the exit animation works
.toast:not(.animate-in) {
  opacity: 0;
  transform: translateX(50px);
}

.toast-icon {
  height: 20px;
  width: 20px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast-message {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toast-close {
  background: none;
  border: none;
  padding: 4px;
  color: #9ca3af;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #4b5563;
  }
}

// Remove unused styles
// .warning, .info, and their associated styles have been removed
// Removed unused toast-content styles
// Simplified color palette and reduced visual complexity
